body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.quickstart, .quickstart .quickstartWrapper .col, .news, .news .news-header, .news .news-body, .video, .video .videoWrapper, .bitCoinCash, .productsAndService, .productsAndService .psWrapper .psBody, .productsAndService .psWrapper .psBody .titlePadding .tile, .foot, .foot .footWrapper, .pageFooter, .pageFooter .pageFooterWrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center; }

html {
  font-size: 10px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
  display: block; }

:after, :before {
  box-sizing: border-box; }

body {
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif !important;
  font-size: 15px !important;
  line-height: 1.42857143 !important;
  color: #333;
  background-color: #fff;
  position: relative;
  font-weight: 500 !important; }

a {
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif !important;
  color: #aaa;
  font-weight: 400 !important; }

a:hover {
  text-decoration: none;
  color: white !important; }

.nav {
  width: 100%;
  background: #191919;
  height: 50px;
  border-bottom: 2px solid #f9b016 !important;
  box-sizing: content-box;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding: 0px 16px;
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
  overflow: hidden; }
  .nav h2 {
    color: white;
    margin: 0; }
  .nav ul {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    margin: 0;
    padding: 0 1em;
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap; }
    @media (max-width: 374px) {
      .nav ul {
        display: none; } }
    .nav ul li {
      list-style-type: none;
      border-right: 1px solid #595959;
      transition: color 0.2s !important;
      padding: 0 0.35em;
      white-space: nowrap; }
      .nav ul li a {
        color: #aaa; }
      @media (max-width: 734px) {
        .nav ul li:nth-child(1) {
          display: none; } }
      @media (max-width: 786px) {
        .nav ul li:nth-child(2) {
          display: none; } }
      @media (max-width: 842px) {
        .nav ul li:nth-child(3) {
          display: none; } }
      @media (max-width: 906px) {
        .nav ul li:nth-child(4) {
          display: none; } }
      .nav ul li:nth-child(5) {
        border-right: none;
        padding: 0; }
        @media (max-width: 398px) {
          .nav ul li:nth-child(5) {
            margin-left: -15px; } }
        .nav ul li:nth-child(5) a {
          padding: 0;
          border: 2px solid #fab915;
          border-radius: 4px;
          padding: 5px;
          margin-left: 10px;
          color: #fab915; }
          .nav ul li:nth-child(5) a:hover {
            background: #fab915;
            color: white; }
        @media (max-width: 565px) {
          .nav ul li:nth-child(5) span {
            display: none; } }
      .nav ul li:nth-child(6) {
        border-right: none;
        padding: 0; }
        .nav ul li:nth-child(6) a {
          border-radius: 4px;
          padding: 5px;
          margin-left: 10px;
          border: 2px solid white;
          color: white; }
          @media (max-width: 450px) {
            .nav ul li:nth-child(6) a {
              display: none; } }
          .nav ul li:nth-child(6) a:hover {
            background: white;
            color: black !important; }
  .nav .psa {
    display: block !important;
    position: absolute !important;
    font-size: 0.9em !important;
    right: 257px !important;
    text-decoration: none !important;
    color: #d69306 !important;
    text-align: right !important;
    padding-top: 1px !important; }
    @media (max-width: 655px) {
      .nav .psa {
        display: none !important; } }
    .nav .psa:hover {
      color: #f9b016 !important; }
  .nav .bchusd {
    position: absolute;
    right: 170px;
    padding-right: 12px; }
    .nav .bchusd a {
      color: #aaa; }
      .nav .bchusd a .label {
        font-size: 10px !important;
        color: #aaa; }
    @media (max-width: 675px) {
      .nav .bchusd {
        right: 115px !important; } }
    @media (max-width: 328px) {
      .nav .bchusd {
        display: none; } }
  .nav .btcusd {
    position: absolute;
    right: 100px;
    padding-right: 12px; }
    .nav .btcusd a {
      color: #aaa; }
      .nav .btcusd a .label {
        font-size: 10px !important;
        color: #aaa; }
    @media (max-width: 675px) {
      .nav .btcusd {
        right: 50px !important; } }
    @media (max-width: 328px) {
      .nav .btcusd {
        display: none; } }
  .nav .hmbgrMenu {
    height: 32px;
    position: fixed;
    right: 16px !important;
    cursor: pointer !important;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-align-items: center;
            align-items: center; }
    .nav .hmbgrMenu::before {
      position: relative;
      content: 'menu';
      color: #ccc;
      padding-right: 0.25em;
      left: -6px; }
      @media (max-width: 675px) {
        .nav .hmbgrMenu::before {
          display: none; } }
    .nav .hmbgrMenu .hmbgr {
      visibility: visible;
      position: relative;
      right: 0px;
      display: inline-block;
      width: 20px;
      height: 2px;
      background-color: #ccc;
      border-radius: 1.5px;
      cursor: pointer;
      transition: background-color 0.2s ease, -webkit-transform 0.5s ease !important;
      transition: transform 0.5s ease, background-color 0.2s ease !important;
      transition: transform 0.5s ease, background-color 0.2s ease, -webkit-transform 0.5s ease !important; }
      .nav .hmbgrMenu .hmbgr::before {
        visibility: visible;
        position: absolute;
        content: '';
        -webkit-transform: translate(0, -6px);
                transform: translate(0, -6px);
        width: 20px !important;
        height: 2px !important;
        background-color: #ccc;
        border-radius: 1.5px;
        line-height: 0px;
        cursor: pointer;
        transition: background-color 0.2s, -webkit-transform 0.6s !important;
        transition: transform 0.6s, background-color 0.2s !important;
        transition: transform 0.6s, background-color 0.2s, -webkit-transform 0.6s !important; }
      .nav .hmbgrMenu .hmbgr::after {
        visibility: visible;
        position: absolute;
        content: '';
        -webkit-transform: translate(0, 6px);
                transform: translate(0, 6px);
        width: 20px;
        height: 2px;
        background-color: #ccc;
        border-radius: 1.5px;
        line-height: 0px;
        cursor: pointer;
        transition: background-color 0.2s, -webkit-transform 0.6s !important;
        transition: transform 0.6s, background-color 0.2s !important;
        transition: transform 0.6s, background-color 0.2s, -webkit-transform 0.6s !important; }
    .nav .hmbgrMenu .hmbgrOpen {
      visibility: hidden;
      background-color: red;
      -webkit-transform: rotateY(-180deg);
              transform: rotateY(-180deg); }
      .nav .hmbgrMenu .hmbgrOpen::before {
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
        background-color: red; }
      .nav .hmbgrMenu .hmbgrOpen::after {
        -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg);
        background-color: red; }

.menuPopup {
  position: fixed;
  top: 50px;
  left: 0px;
  width: 100%;
  background-color: rgba(25, 25, 25, 0.95);
  padding: 2em 0 1em 0 !important;
  font-size: 16px !important;
  font-family: "museo-sans-rounded", Helvetica, Arial, sans-serif !important;
  font-weight: 300 !important;
  line-height: 1em !important;
  z-index: 10000000 !important; }
  @media (max-width: 849px) {
    .menuPopup {
      overflow-y: scroll !important;
      bottom: 0 !important; } }
  .menuPopup .menuWrap {
    box-sizing: border-box;
    max-width: 1225px !important;
    padding: 0 0px !important;
    margin: 0 16px !important;
    position: relative; }
    .menuPopup .menuWrap .cat {
      float: left !important;
      box-sizing: border-box !important;
      width: 20% !important;
      padding-right: 1% !important;
      height: 15.8125em !important; }
      .menuPopup .menuWrap .cat .label {
        font-size: 1.25em !important;
        line-height: 1.0em !important;
        padding-bottom: 0.5em !important;
        color: white !important;
        text-decoration: none !important;
        display: block !important; }
      .menuPopup .menuWrap .cat ul {
        padding: 0;
        list-style-type: none; }
        .menuPopup .menuWrap .cat ul li {
          font-size: 14px !important;
          list-style-type: none !important;
          padding: 0 !important;
          line-height: 1.5em !important; }
      @media (max-width: 849px) {
        .menuPopup .menuWrap .cat {
          width: 25% !important; } }
      @media (max-width: 649px) {
        .menuPopup .menuWrap .cat {
          width: 33% !important; } }
      @media (max-width: 549px) {
        .menuPopup .menuWrap .cat {
          float: none !important;
          width: 100% !important;
          height: auto !important;
          margin-bottom: 30px !important; } }
    .menuPopup .menuWrap .social {
      text-align: center; }
    .menuPopup .menuWrap .clear {
      clear: both !important;
      height: 0 !important; }

.header {
  position: relative;
  top: 50px !important;
  padding: 100px 0;
  background-color: #191919;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  background: url(../../static/media/wallet-buy-bg6.d53039c4.jpg) center no-repeat;
  background-size: cover;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 52px; }
  @media (max-width: 992px) {
    .header {
      padding: 0;
      background: url(../../static/media/mobile-header-bg.7a2ae277.jpg) center no-repeat;
      background-size: cover; } }
  .header .walletBuy {
    height: 300px;
    width: 23%;
    position: absolute;
    background-image: url(../../static/media/wallet-buy.f2bf0010.webp);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    display: inline-block;
    z-index: 9999; }
    @media (max-width: 1168px) {
      .header .walletBuy {
        display: none !important; } }
  .header .container {
    width: 90%;
    max-width: 1400px; }
    @media (max-width: 992px) {
      .header .container .row {
        display: block; } }
    .header .container .row .col-md-6 h1 {
      margin-bottom: 20px;
      border-bottom: 2px solid #fab915;
      display: inline-block;
      padding-bottom: 20px;
      font-weight: 600;
      font-size: 35px;
      color: white; }
      @media (max-width: 992px) {
        .header .container .row .col-md-6 h1 {
          font-size: 30px !important;
          max-width: 100% !important;
          display: block; } }
    .header .container .row .col-md-6 p {
      color: white;
      font-size: 26px; }
    .header .container .row .col-md-6 .wallet-h4 {
      color: #fab915;
      letter-spacing: 1px;
      margin-bottom: 30px;
      margin-top: 5px;
      font-size: 18px; }
    .header .container .row .col-md-6 .wallet-icons img {
      width: 200px;
      margin-bottom: 30px; }
    .header .container .row .col-md-6 .download-btn {
      color: white;
      padding: 0px 30px;
      font-weight: 600 !important;
      font-size: 16px;
      text-align: center;
      border-radius: 100px !important;
      background-color: #fab915 !important;
      height: 50px;
      line-height: 50px;
      border: none;
      text-decoration: none !important;
      display: inline-block !important;
      transition: all 0.15s ease-in-out; }
      .header .container .row .col-md-6 .download-btn:hover {
        background-color: #E7A603 !important;
        transition: all 0.15s ease-in-out; }
      @media (max-width: 992px) {
        .header .container .row .col-md-6 .download-btn {
          position: relative;
          width: 100% !important;
          margin-bottom: 10px; } }
    @media (max-width: 992px) {
      .header .container .row .col-md-6 {
        padding: 60px 0;
        text-align: center !important;
        margin: auto;
        max-width: 100%; } }
    .header .container .row .header-right {
      text-align: right; }
      .header .container .row .header-right .buy-h4 {
        color: #444;
        font-size: 18px;
        margin-bottom: 30px;
        margin-top: 5px; }
      .header .container .row .header-right .buy-icons img {
        width: 200px;
        margin-bottom: 30px; }
      .header .container .row .header-right .buyCoinBt {
        color: #fff !important;
        padding-left: 30px;
        padding-right: 30px;
        font-size: 16px;
        font-weight: 600 !important;
        text-align: center;
        border-radius: 100px !important;
        background-color: #444 !important;
        height: 50px;
        line-height: 50px;
        border: none;
        text-decoration: none !important;
        display: inline-block !important;
        transition: all 0.15s ease-in-out; }
        .header .container .row .header-right .buyCoinBt:hover {
          background-color: #333 !important; }
        @media (max-width: 992px) {
          .header .container .row .header-right .buyCoinBt {
            width: 100% !important;
            margin-bottom: 10px; } }
      @media (max-width: 992px) {
        .header .container .row .header-right {
          padding: 60px 0;
          text-align: center !important;
          margin: auto; } }

.quickstart {
  position: relative;
  background-color: #e1e1e1;
  margin: 2px 0px; }
  .quickstart .quickstartWrapper {
    width: 90%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row; }
    .quickstart .quickstartWrapper .col {
      padding: 15px;
      border-left: 2px solid #fff;
      background-color: #e1e1e1;
      transition: background 0.4s; }
      .quickstart .quickstartWrapper .col:nth-child(3) {
        border-right: 2px solid #fff; }
      .quickstart .quickstartWrapper .col:hover {
        background-color: #fab915; }
      @media (max-width: 992px) {
        .quickstart .quickstartWrapper .col {
          border: none !important;
          border-bottom: 2px solid #fff !important;
          padding: 25px !important; } }
    @media (max-width: 992px) {
      .quickstart .quickstartWrapper {
        -webkit-flex-direction: column !important;
                flex-direction: column !important;
        width: 100%; } }

.news {
  -webkit-flex-direction: column;
          flex-direction: column;
  margin: 0px;
  padding: 60px 0 80px; }
  @media (max-width: 768px) {
    .news {
      padding: 40px 0 80px; } }
  .news .news-header {
    margin-top: 0;
    margin-bottom: 25px;
    width: 100% !important; }
    @media (max-width: 768px) {
      .news .news-header {
        margin-bottom: 15px; } }
    .news .news-header h1 {
      font-size: 40px !important; }
      @media (max-width: 992px) {
        .news .news-header h1 {
          font-size: 30px !important; } }
  .news .news-body {
    width: 90%; }
    @media (max-width: 992px) {
      .news .news-body {
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap; } }
    .news .news-body .news-title {
      -webkit-flex: 1 1;
              flex: 1 1;
      margin: 0;
      height: 250px;
      margin: 10px;
      -webkit-align-items: flex-end;
              align-items: flex-end;
      overflow: hidden;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column; }
      @media (max-width: 992px) {
        .news .news-body .news-title {
          min-width: 33%;
          margin: 9px; } }
      @media (max-width: 768px) {
        .news .news-body .news-title {
          min-width: 50%;
          margin: 8px; } }
      .news .news-body .news-title .news-headline {
        -webkit-flex: 0 1;
                flex: 0 1;
        bottom: 0px !important;
        text-align: left;
        color: #444;
        background-color: #fff;
        font-size: 16px;
        padding: 15px 15px;
        line-height: 20px;
        font-weight: 700;
        margin-bottom: 10px;
        margin: 10px;
        -webkit-transform: translate(0, calc(-100% - 20px));
                transform: translate(0, calc(-100% - 20px));
        z-index: 1; }
      .news .news-body .news-title:nth-child(1)::before {
        -webkit-flex: 1 1;
                flex: 1 1;
        content: '';
        min-width: 100%;
        min-height: 100%;
        background: white url(../../static/media/promises.1b0048ea.webp) no-repeat center;
        background-size: cover !important;
        transition: background-size 0.5s, -webkit-transform 0.5s !important;
        transition: transform 0.5s, background-size 0.5s !important;
        transition: transform 0.5s, background-size 0.5s, -webkit-transform 0.5s !important; }
      .news .news-body .news-title:nth-child(1):hover {
        cursor: pointer; }
        .news .news-body .news-title:nth-child(1):hover::before {
          -webkit-transform: scale(1.15);
                  transform: scale(1.15); }
        .news .news-body .news-title:nth-child(1):hover .news-headline {
          background: #fab915; }
      .news .news-body .news-title:nth-child(2)::before {
        -webkit-flex: 1 1;
                flex: 1 1;
        content: '';
        min-width: 100%;
        min-height: 100%;
        background: white url(../../static/media/nutrrrino8888.c670db6b.webp) no-repeat center;
        background-size: cover !important;
        transition: background-size 0.5s, -webkit-transform 0.5s !important;
        transition: transform 0.5s, background-size 0.5s !important;
        transition: transform 0.5s, background-size 0.5s, -webkit-transform 0.5s !important; }
      .news .news-body .news-title:nth-child(2):hover {
        cursor: pointer; }
        .news .news-body .news-title:nth-child(2):hover::before {
          -webkit-transform: scale(1.15);
                  transform: scale(1.15); }
        .news .news-body .news-title:nth-child(2):hover .news-headline {
          background: #fab915; }
      .news .news-body .news-title:nth-child(3)::before {
        -webkit-flex: 1 1;
                flex: 1 1;
        content: '';
        min-width: 100%;
        min-height: 100%;
        background: white url(../../static/media/hacker-arrest.563ff6e2.webp) no-repeat center;
        background-size: cover !important;
        transition: background-size 0.5s, -webkit-transform 0.5s !important;
        transition: transform 0.5s, background-size 0.5s !important;
        transition: transform 0.5s, background-size 0.5s, -webkit-transform 0.5s !important; }
      .news .news-body .news-title:nth-child(3):hover {
        cursor: pointer; }
        .news .news-body .news-title:nth-child(3):hover::before {
          -webkit-transform: scale(1.15);
                  transform: scale(1.15); }
        .news .news-body .news-title:nth-child(3):hover .news-headline {
          background: #fab915; }
      .news .news-body .news-title:nth-child(4)::before {
        -webkit-flex: 1 1;
                flex: 1 1;
        content: '';
        min-width: 100%;
        min-height: 100%;
        background: white url(../../static/media/derivatives.116f183d.webp) no-repeat center;
        background-size: cover !important;
        transition: background-size 0.5s, -webkit-transform 0.5s !important;
        transition: transform 0.5s, background-size 0.5s !important;
        transition: transform 0.5s, background-size 0.5s, -webkit-transform 0.5s !important; }
      .news .news-body .news-title:nth-child(4):hover {
        cursor: pointer; }
        .news .news-body .news-title:nth-child(4):hover::before {
          -webkit-transform: scale(1.15);
                  transform: scale(1.15); }
        .news .news-body .news-title:nth-child(4):hover .news-headline {
          background: #fab915; }

.video {
  padding: 60px 0;
  background-color: #F9F9F9; }
  .video .videoWrapper {
    width: 90%; }
    @media (max-width: 992px) {
      .video .videoWrapper {
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap; } }
    .video .videoWrapper .readme {
      -webkit-flex: 1 1;
              flex: 1 1;
      position: relative;
      min-height: 1px;
      padding-right: 15px;
      padding-left: 15px;
      -webkit-flex-direction: column;
              flex-direction: column;
      min-width: 50%; }
      @media (max-width: 992px) {
        .video .videoWrapper .readme {
          min-width: 100%; } }
      @media (max-width: 768px) {
        .video .videoWrapper .readme {
          display: -webkit-flex;
          display: flex;
          -webkit-justify-content: center;
                  justify-content: center;
          -webkit-align-items: center;
                  align-items: center;
          padding-right: 0px; } }
      .video .videoWrapper .readme h1 {
        font-size: 40px;
        margin-top: 0px;
        color: #333;
        margin-bottom: 25px; }
        @media (max-width: 992px) {
          .video .videoWrapper .readme h1 {
            font-size: 30px !important; } }
      .video .videoWrapper .readme p {
        color: #333;
        font-size: 18px;
        margin-bottom: 25px;
        font-weight: 600 !important;
        padding-right: 30px; }
        @media (max-width: 768px) {
          .video .videoWrapper .readme p {
            padding-right: 20px; } }
      .video .videoWrapper .readme a {
        color: #fff !important;
        padding-left: 30px;
        padding-right: 30px;
        font-size: 16px;
        text-align: center;
        border-radius: 100px !important;
        background-color: #fab915 !important;
        height: 50px;
        line-height: 50px;
        border: none;
        text-decoration: none !important;
        display: inline-block !important; }
        @media (max-width: 768px) {
          .video .videoWrapper .readme a {
            width: 100%;
            margin-right: 15px; } }
        .video .videoWrapper .readme a:hover {
          background-color: #E7A603 !important;
          transition: background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out; }
    .video .videoWrapper .videoBox {
      min-width: 50%;
      position: relative; }
      .video .videoWrapper .videoBox iframe {
        margin-top: 26px;
        width: 44vw !important;
        height: 25vw !important; }
        @media (min-height: 1200px) {
          .video .videoWrapper .videoBox iframe {
            margin-top: 26px;
            width: 43vw !important;
            height: 25vw !important; } }
        @media (min-height: 1000px) {
          .video .videoWrapper .videoBox iframe {
            margin-top: 26px;
            width: 43vw !important;
            height: 24vw !important; } }
        @media (max-width: 992px) {
          .video .videoWrapper .videoBox iframe {
            margin-top: 26px;
            width: 82vw !important;
            height: 67vh !important; } }
        @media (max-width: 832px) {
          .video .videoWrapper .videoBox iframe {
            margin-top: 26px;
            width: 82vw !important;
            height: 61vh !important; } }
        @media (max-width: 768px) {
          .video .videoWrapper .videoBox iframe {
            margin-top: 26px;
            width: 85vw !important;
            height: 49vw !important; } }
        @media (max-width: 704px) {
          .video .videoWrapper .videoBox iframe {
            margin-top: 26px;
            width: 82vw !important;
            height: 49vmax !important; } }
        @media (max-width: 640px) {
          .video .videoWrapper .videoBox iframe {
            margin-top: 26px;
            width: 82vw !important;
            height: 44vh !important; } }
        @media (max-width: 576px) {
          .video .videoWrapper .videoBox iframe {
            margin-top: 26px;
            width: 82vw !important;
            height: 45vmax !important; } }
        @media (max-width: 512px) {
          .video .videoWrapper .videoBox iframe {
            margin-top: 26px;
            width: 82vw !important;
            height: 36vh !important; } }
        @media (max-width: 432px) {
          .video .videoWrapper .videoBox iframe {
            margin-top: 26px;
            width: 80vw !important;
            height: 50vmin !important; } }

.bitCoinCash {
  padding: 70px 0 !important;
  background-color: #000;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAKElEQVQYVzWKwREAMAyCzG6M7W7pean8QAdYfWxrGiLAhUqOI2mzlAdX8g9l8quBnQAAAABJRU5ErkJggg==) repeat; }
  .bitCoinCash .bitCoinCashWrapper {
    width: 90%;
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    text-align: center; }
    .bitCoinCash .bitCoinCashWrapper img {
      position: relative;
      width: 400px !important;
      margin-bottom: 60px;
      vertical-align: middle; }
      @media (max-width: 768px) {
        .bitCoinCash .bitCoinCashWrapper img {
          width: 300px !important; } }
    .bitCoinCash .bitCoinCashWrapper p {
      font-size: 18px;
      margin-bottom: 50px;
      font-weight: 500;
      color: #fff;
      padding: 20px; }
    .bitCoinCash .bitCoinCashWrapper a {
      font-weight: 600 !important;
      color: #fff !important;
      padding-left: 30px;
      padding-right: 30px;
      font-size: 16px;
      text-align: center;
      border-radius: 100px !important;
      background-color: #fab915 !important;
      height: 50px;
      line-height: 50px;
      border: none;
      text-decoration: none !important;
      display: inline-block !important;
      margin-right: 24px; }
      .bitCoinCash .bitCoinCashWrapper a:hover {
        background-color: #E7A603 !important; }
      @media (max-width: 768px) {
        .bitCoinCash .bitCoinCashWrapper a {
          padding-left: 0px !important;
          padding-right: 0px !important;
          font-size: 16px;
          height: 50px;
          line-height: 50px;
          width: 100% !important;
          margin-bottom: 20px; } }

.productsAndService {
  padding: 30px 0;
  background-color: #F9F9F9; }
  .productsAndService .psWrapper {
    width: 90%;
    max-width: 1400px;
    text-align: center; }
    .productsAndService .psWrapper .psHeader {
      margin-bottom: 40px; }
      .productsAndService .psWrapper .psHeader h1 {
        font-family: 'Montserrat', sans-serif;
        font-size: 40px;
        color: #333;
        margin-bottom: 0px;
        margin-top: 0;
        font-weight: 700;
        margin: 0.67em 0; }
        @media (max-width: 992px) {
          .productsAndService .psWrapper .psHeader h1 {
            font-size: 30px !important; } }
    .productsAndService .psWrapper .psBody {
      -webkit-flex-wrap: wrap;
              flex-wrap: wrap; }
      .productsAndService .psWrapper .psBody .titlePadding {
        width: 31%;
        margin: 0px 10px; }
        @media (max-width: 1200px) {
          .productsAndService .psWrapper .psBody .titlePadding {
            min-width: 47.5%; } }
        @media (max-width: 992px) {
          .productsAndService .psWrapper .psBody .titlePadding {
            min-width: 98%; } }
        .productsAndService .psWrapper .psBody .titlePadding .tile {
          background-color: #fff;
          padding: 0px 30px;
          text-align: center;
          margin-bottom: 20px;
          border-radius: 5px;
          border: 1px solid #e2e2e2;
          transition: all 0.15s ease-in-out;
          height: 360px;
          -webkit-flex-direction: column;
                  flex-direction: column; }
          .productsAndService .psWrapper .psBody .titlePadding .tile img {
            height: 70px;
            margin-bottom: 10px;
            vertical-align: middle !important; }
          .productsAndService .psWrapper .psBody .titlePadding .tile h3 {
            color: #333;
            margin-bottom: 10px;
            font-weight: 700;
            font-size: 22px;
            margin-top: 20px;
            line-height: 1.1; }
          .productsAndService .psWrapper .psBody .titlePadding .tile p {
            color: #333;
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 30px; }
          .productsAndService .psWrapper .psBody .titlePadding .tile a {
            font-family: 'Montserrat', sans-serif;
            font-weight: 700 !important;
            color: #fff !important;
            padding-left: 30px;
            padding-right: 30px;
            font-size: 16px;
            text-align: center;
            border-radius: 100px !important;
            background-color: #fab915 !important;
            height: 50px;
            line-height: 50px;
            border: none;
            text-decoration: none !important;
            display: inline-block !important; }
            @media (max-width: 768px) {
              .productsAndService .psWrapper .psBody .titlePadding .tile a {
                width: 100% !important; } }
            .productsAndService .psWrapper .psBody .titlePadding .tile a:hover {
              transition: background 0.5s;
              background: #E7A603 !important; }

.foot .footWrapper {
  width: 90%;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 100px 0px; }
  .foot .footWrapper h1 {
    color: #333;
    text-align: center;
    font-size: 28px;
    margin-bottom: 30px; }
  .foot .footWrapper a {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700 !important;
    color: #fff !important;
    padding-left: 30px;
    padding-right: 30px;
    font-size: 16px;
    text-align: center;
    border-radius: 100px !important;
    background-color: #fab915 !important;
    height: 50px;
    line-height: 50px;
    border: none;
    text-decoration: none !important;
    display: inline-block !important; }
    .foot .footWrapper a:hover {
      transition: background 0.5s;
      background: #E7A603 !important; }
    @media (max-width: 768px) {
      .foot .footWrapper a {
        padding-left: 0px !important;
        padding-right: 0px !important;
        font-size: 16px;
        height: 50px;
        line-height: 50px;
        width: 90% !important; } }

.pageFooter {
  background: #191919 url(../../static/media/b-tilt-5.d64a35c3.png) right -275px no-repeat !important;
  margin-top: 0.5em !important;
  padding-top: 2.0em !important;
  font-weight: 300 !important;
  border-top: 1px solid #464a4c !important; }
  @media (min-width: 1001px) {
    .pageFooter:hover .feedback {
      right: 3em !important; } }
  .pageFooter h3 {
    color: #fab915 !important;
    text-transform: uppercase !important;
    font-size: 1.36em !important;
    margin-bottom: 1.15em !important;
    font-weight: 500 !important;
    line-height: 1.00em !important;
    padding: 0 !important;
    font-family: "museo-sans-rounded",'MuseoSansRounded-300',Helvetica,Arial,sans-serif !important;
    -webkit-font-smoothing: antialiased !important;
    -webkit-text-size-adjust: 100% !important; }
  .pageFooter p, .pageFooter span {
    color: #a3a3a3 !important;
    font-family: "museo-sans-rounded",'MuseoSansRounded-300',Helvetica,Arial,sans-serif !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 1.5em !important;
    padding-left: 0px !important;
    -webkit-font-smoothing: antialiased !important;
    -webkit-text-size-adjust: 100% !important; }
  .pageFooter .feedback {
    position: fixed;
    background: black url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiBzdHlsZT0iaGVpZ2h0OjM3cHg7ZmlsbDojRkFCOTE1O3ZlcnRpY2FsLWFsaWduOm1pZGRsZTsiPjxwYXRoIGQ9Ik00ODAgMjAxLjY2N2MwLTE0LjkzMy03LjQ2OS0yOC44MDMtMjAuMjcxLTM2LjI2NkwyNTYgNjQgNTIuMjcxIDE2NS40MDFDNDAuNTMxIDE3Mi44NjQgMzIgMTg2LjczNCAzMiAyMDEuNjY3djIwMy42NjZDMzIgNDI4LjgwMiA1MS4xOTcgNDQ4IDc0LjY2NiA0NDhoMzYyLjY2OEM0NjAuODAzIDQ0OCA0ODAgNDI4LjgwMiA0ODAgNDA1LjMzM1YyMDEuNjY3ek0yNTYgMzA0TDg0LjYzMSAxOTIgMjU2IDEwNi42NjcgNDI3LjM2OSAxOTIgMjU2IDMwNHoiLz48L3N2Zz4=") center no-repeat;
    background-size: 80%;
    border: 1px solid #fab915 !important;
    right: -6em !important;
    bottom: 6em !important;
    width: 50px !important;
    height: 50px !important;
    z-index: 1000000000 !important;
    border-radius: 50%;
    transition: right 1s, background 1s; }
    .pageFooter .feedback .content {
      width: 100px;
      white-space: nowrap;
      font-size: 8px !important;
      background-color: #fab915;
      border-radius: 5px;
      color: white !important;
      padding: 3px 16px !important;
      text-align: center;
      position: absolute;
      left: 50%;
      -webkit-transform: translate(-50%, -150%);
              transform: translate(-50%, -150%);
      opacity: 0; }
      .pageFooter .feedback .content .square {
        position: absolute !important;
        width: 8px !important;
        height: 8px !important;
        left: 50% !important;
        top: 100% !important;
        -webkit-transform: translate(-50%, -50%) rotate(45deg);
                transform: translate(-50%, -50%) rotate(45deg);
        background-color: #fab915; }
    .pageFooter .feedback:hover {
      background-color: #fab915 !important;
      background: black url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiBzdHlsZT0iaGVpZ2h0OjM3cHg7ZmlsbDojRkZGRkZGO3ZlcnRpY2FsLWFsaWduOm1pZGRsZTsiPjxwYXRoIGQ9Ik00ODAgMjAxLjY2N2MwLTE0LjkzMy03LjQ2OS0yOC44MDMtMjAuMjcxLTM2LjI2NkwyNTYgNjQgNTIuMjcxIDE2NS40MDFDNDAuNTMxIDE3Mi44NjQgMzIgMTg2LjczNCAzMiAyMDEuNjY3djIwMy42NjZDMzIgNDI4LjgwMiA1MS4xOTcgNDQ4IDc0LjY2NiA0NDhoMzYyLjY2OEM0NjAuODAzIDQ0OCA0ODAgNDI4LjgwMiA0ODAgNDA1LjMzM1YyMDEuNjY3ek0yNTYgMzA0TDg0LjYzMSAxOTIgMjU2IDEwNi42NjcgNDI3LjM2OSAxOTIgMjU2IDMwNHoiLz48L3N2Zz4=") center no-repeat;
      background-size: 80%; }
      .pageFooter .feedback:hover .content {
        opacity: 1;
        transition: opacity 0.6s; }
  .pageFooter .pageFooterWrapper {
    width: 100%;
    -webkit-flex-direction: column;
            flex-direction: column;
    font-size: 13px !important; }
    .pageFooter .pageFooterWrapper hr {
      margin: 5.0em 0 0 0 !important;
      border-top: 1px solid #444 !important;
      padding: 2.0em 0 0 0 !important;
      width: 100%;
      height: 1px !important; }
    .pageFooter .pageFooterWrapper .theWrapper {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row !important;
              flex-direction: row !important; }
      .pageFooter .pageFooterWrapper .theWrapper:nth-child(1) {
        -webkit-flex: 11 1;
                flex: 11 1;
        width: 95%;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
                flex-direction: row;
        padding: 0px 30px; }
        .pageFooter .pageFooterWrapper .theWrapper:nth-child(1) .quote {
          -webkit-flex: 1 1;
                  flex: 1 1;
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: column;
                  flex-direction: column;
          -webkit-align-items: flex-start;
                  align-items: flex-start;
          max-width: 25%;
          padding-right: 50px; }
        .pageFooter .pageFooterWrapper .theWrapper:nth-child(1) .quickLinks {
          -webkit-flex: 1 1;
                  flex: 1 1;
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: column;
                  flex-direction: column;
          max-width: 25%; }
          .pageFooter .pageFooterWrapper .theWrapper:nth-child(1) .quickLinks ul {
            display: -webkit-flex;
            display: flex;
            -webkit-flex-direction: column;
                    flex-direction: column;
            -webkit-flex-wrap: wrap !important;
                    flex-wrap: wrap !important;
            max-height: 32em !important;
            padding-left: 0; }
            .pageFooter .pageFooterWrapper .theWrapper:nth-child(1) .quickLinks ul li {
              margin: 0.45em 0px;
              padding-right: 0px;
              font-weight: 500 !important;
              list-style-type: none;
              max-width: 8em;
              line-height: 1em !important; }
              .pageFooter .pageFooterWrapper .theWrapper:nth-child(1) .quickLinks ul li a {
                text-decoration: none !important;
                color: #fff !important; }
                .pageFooter .pageFooterWrapper .theWrapper:nth-child(1) .quickLinks ul li a:hover {
                  color: #E7A603 !important;
                  text-decoration: underline !important; }
        .pageFooter .pageFooterWrapper .theWrapper:nth-child(1) .about {
          -webkit-flex: 1 1;
                  flex: 1 1;
          max-width: 25%;
          padding-left: 50px; }
          .pageFooter .pageFooterWrapper .theWrapper:nth-child(1) .about a {
            text-decoration: none !important;
            color: #fff !important; }
            .pageFooter .pageFooterWrapper .theWrapper:nth-child(1) .about a:hover {
              color: #E7A603 !important;
              text-decoration: underline !important; }
        .pageFooter .pageFooterWrapper .theWrapper:nth-child(1) .newForm {
          -webkit-flex: 1 1;
                  flex: 1 1;
          max-width: 25%;
          padding-left: 50px; }
          .pageFooter .pageFooterWrapper .theWrapper:nth-child(1) .newForm a {
            background-color: transparent !important;
            border: 2px solid #fab915 !important;
            color: #fab915 !important;
            padding: 0.5em 1.0em !important;
            display: block !important;
            margin-bottom: 1.0em !important;
            font-size: 1.1em !important;
            border-radius: 0.25em !important;
            cursor: pointer !important;
            transition: background-color 0.25s linear !important;
            text-align: center !important;
            text-transform: uppercase !important;
            -webkit-font-smoothing: antialiased !important;
            -webkit-text-size-adjust: 100% !important;
            font-family: "museo-sans-rounded",'MuseoSansRounded-300',Helvetica,Arial,sans-serif !important; }
            .pageFooter .pageFooterWrapper .theWrapper:nth-child(1) .newForm a[class=mobilefeedback] {
              border: 0px !important;
              display: none !important; }
            .pageFooter .pageFooterWrapper .theWrapper:nth-child(1) .newForm a:hover {
              background-color: #fab915 !important;
              text-decoration: none !important;
              color: #fff !important; }
        @media (max-width: 1000px) {
          .pageFooter .pageFooterWrapper .theWrapper:nth-child(1) {
            -webkit-flex-wrap: wrap;
                    flex-wrap: wrap; }
            .pageFooter .pageFooterWrapper .theWrapper:nth-child(1) .quote {
              min-width: 50%;
              padding: 0px 25px; }
            .pageFooter .pageFooterWrapper .theWrapper:nth-child(1) .quickLinks {
              min-width: 50%;
              padding: 0px 25px; }
            .pageFooter .pageFooterWrapper .theWrapper:nth-child(1) .about {
              min-width: 50%;
              padding: 0px 25px;
              margin-top: 50px; }
            .pageFooter .pageFooterWrapper .theWrapper:nth-child(1) .newForm {
              min-width: 50%;
              padding: 0px 25px;
              margin-top: 50px; }
              .pageFooter .pageFooterWrapper .theWrapper:nth-child(1) .newForm a[class=mobilefeedback] {
                border: 0px !important;
                display: block !important; } }
        @media (max-width: 470px) {
          .pageFooter .pageFooterWrapper .theWrapper:nth-child(1) {
            -webkit-flex-wrap: wrap;
                    flex-wrap: wrap; }
            .pageFooter .pageFooterWrapper .theWrapper:nth-child(1) .quote {
              min-width: 100%;
              padding: 0px 25px; }
            .pageFooter .pageFooterWrapper .theWrapper:nth-child(1) .quickLinks {
              min-width: 100%;
              padding: 0px 25px;
              margin-top: 50px; }
            .pageFooter .pageFooterWrapper .theWrapper:nth-child(1) .about {
              min-width: 100%;
              padding: 0px 25px;
              margin-top: 50px; }
            .pageFooter .pageFooterWrapper .theWrapper:nth-child(1) .newForm {
              min-width: 100%;
              padding: 0px 25px;
              margin-top: 50px; } }
      .pageFooter .pageFooterWrapper .theWrapper:nth-child(3) {
        width: 100%;
        height: 50px;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        -webkit-flex-direction: row !important;
                flex-direction: row !important;
        margin-bottom: 20px; }
        .pageFooter .pageFooterWrapper .theWrapper:nth-child(3) .leftX {
          -webkit-flex: 1 1;
                  flex: 1 1;
          text-align: left;
          padding-left: 50px;
          color: #8A8A8A !important; }
        .pageFooter .pageFooterWrapper .theWrapper:nth-child(3) .rightX {
          -webkit-flex: 1 1;
                  flex: 1 1;
          text-align: right;
          padding-right: 50px; }
      @media (max-width: 600px) {
        .pageFooter .pageFooterWrapper .theWrapper {
          padding: 0px !important; } }

